.wrap-item {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
  &:hover {
    background-color: #95deff;
  }

  .title {
    color: var(--color-primary);
  }
  .item {
    margin: 0;
  }
  .content {
    font-weight: bold;
  }
}
