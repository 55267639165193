.dropdown-filter {
  position: relative;

  &.focus.is-invalid {
    & ~ .invalid-tooltip {
      display: block;
    }
    &.dropup ~ .invalid-tooltip {
      top: 100%;
      bottom: unset;
    }
  }

  & + label {
    background: #fff;
    padding: 0 3px;
    font-size: 0.8rem;
    transform: translate(0, -50%);
    opacity: 1 !important;
  }

  .dropdown-filter-input {
    padding-right: calc(1.5rem + 14px);
    cursor: pointer;

    &:focus ~ .dropdown-filter-container .dropdown-filter-indicator {
      color: hsl(0, 0%, 40%);
    }
  }

  .dropdown-filter-container {
    position: absolute;
    top: 0;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    margin: 1px;
    width: 100%;
    pointer-events: none;

    .dropdown-filter-value {
      padding: 0.375rem 0.75rem;
      padding-right: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #495057;
    }
    .dropdown-filter-indicator {
      padding: 0.375rem 0.5rem;
      color: hsl(0, 0%, 80%);
      position: relative;

      .dropdown-filter-indicator-separator {
        background-color: hsl(0, 0%, 80%);
        width: 1px;
        position: absolute;
        left: 1px;
        top: 0.5rem;
        bottom: 0.5rem;
      }

      svg {
        fill: currentColor;
        height: 1.3rem;
        width: 1.3rem;
      }

      &.action {
        padding: 0;
        padding-top: 0.1rem;
        pointer-events: auto;
      }
    }
  }

  .dropdown-filter-menu-container {
    position: absolute;
    z-index: 999;
    top: 100%;
    width: 100%;
    padding: 5px 0;
    background: #fff;
    margin-top: 3px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

    .dropdown-filter-menu {
      max-height: 11rem;
      overflow: auto;
      padding: 0 1rem;
      overflow-x: hidden;

      .dropdown-filter-menu-item {
        cursor: pointer;
        padding: 0.375rem 0.75rem;

        &.hidden {
          display: none;
        }
        &.focus {
          background-color: #deebff;
        }
        &.selected {
          background-color: #2684ff;
          color: #fff;
        }
      }
    }
  }

  &.dropup {
    .dropdown-filter-menu-container {
      top: unset;
      margin-top: unset;
      bottom: 100%;
      margin-bottom: 5px;
    }
  }
}

._form-customize {
  &.is-warning {
    .dropdown-filter.focus ~ .warning-tooltip {
      display: block;
    }
  }
}
