._search-form {
  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 1rem 2rem;
    gap: 1rem 2rem;

    .form-group {
      margin-bottom: 0;
    }

    .search-box {
      display: flex;
      align-items: center;
      position: relative;
      flex: 10 1 auto;

      .form-group {
        margin-bottom: 0;
        width: 100%;

        input {
          border-radius: 15px;
          padding-right: 3rem;
        }
      }

      svg {
        font-size: 1.6rem;
        z-index: 1;
        color: #999;
        position: absolute;
        right: 1rem;
      }
    }

    .form-group {
      margin-bottom: 0;
      flex: 1 auto;
    }

    .wrap-button {
      width: 20%;
      flex: 1 1 auto;

      .btn {
        flex: 0 auto;
        margin-right: 1rem;
      }
    }
  }

  .btn {
    flex: 0 auto;
  }

  .search-data-box {
    width: 100%;
    top: 5px;
    max-height: 500px;
    overflow-x: hidden;
    border-radius: 2px;
    background-color: white;
    z-index: 100;
    left: 0;
    top: 7rem;
  }
}

@media screen and (max-width: 1400px) {
  ._search-form {
    form {
      gap: 1rem 0.8rem;
      
      .form-control {
        &::placeholder {
          font-size: .87rem;
        }
      }
    }
  }
}

@media  (min-width: 361px) and (max-width: 480px)
{
  ._search-form form 
  {
    align-items: center;
    .search-box
    {
      display: flex;
      padding-right: 1rem;
 
    }
    .wrap-button
    {
      width: unset;
    }
    .form-control
    {
      height: auto;
      &::placeholder
      {
        font-size: .9rem ;
      }
    }
    .search-box svg
    {
      font-size: 1.2rem;
    }

  }
}
@media (min-width: 680px) and (max-width: 768px)
{
  ._search-form
  {
    form 
    {
      .wrap-button
      {
        width: auto;
      }
      .search-box
      {
          flex: 1 0 40% ;
      }
    }

  }
}
