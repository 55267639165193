._quality-control-form {
  &.report-export {
    ._form-customize {
      flex: 0 24%;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    column-gap: calc(2px + 0.5vw);
    row-gap: 0.4rem;

    ._form-customize {
      flex-basis: 22%;
      flex-grow: 1;
      &#sensoryQuality {
        flex-basis: 47%;
      }
    }
    #company,
    #country {
      flex-basis: 45%;
    }
    #signSideEffects,
    #complainContent,
    #solution {
      flex-basis: 99%;
    }

    div[class^='form-'] {
      flex: 1 22%;
      display: flex;

      &.form-name {
        flex-basis: 48%;
      }
      &.form-empty {
        .label {
          white-space: nowrap;
          &::after {
            content: '';
            margin-right: calc(2px + 0.5vw);
          }
        }
      }
      .label {
        white-space: nowrap;
        &::after {
          content: ':';
          margin-right: calc(2px + 0.5vw);
        }
      }

      .value {
        font-weight: bold;
      }
    }
  }
}
