._form-customize {
  position: relative;

  label {
    left: 8px;
    position: absolute;
    top: 0;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s;
    pointer-events: none;
    &::after {
      content: ":";
      font-weight: bold;
    }

    span {
      color: #dc3545;
    }

    &.plaintext-label {
      font-size: 0.8rem;
      opacity: 1;
      background: white;
      transform: translateY(-50%);
    }
  }

  .pretty {
    .state {
      label {
        opacity: 1;
      }
    }
  }
  .form-control {
    &.plaintext {
      border: none;
      height: auto;
    }
  }

  input:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label {
    background: rgba($color: #fff, $alpha: 1);
    padding: 0 3px;
    font-size: 0.8rem;
    transform: translate(0, -50%);
    opacity: 1;
  }

  .react-datepicker-wrapper {
    width: 100%;

    & ~ label {
      background: rgba($color: #fff, $alpha: 1);
      padding: 0 3px;
      font-size: 0.8rem;
      transform: translate(0, -50%);
      opacity: 1;
    }

    .react-datepicker__input-container {
      display: flex;

      input {
        padding: 0.375rem 0.75rem;
        border-color: #ccc;
        border-width: 1px;
        width: 100%;
      }
    }
  }

  .calendar-icon {
    position: absolute;
    right: 0.75rem;
    top: 5px;
    font-size: 1.5rem;
    pointer-events: none;
    color: #0c69a6;
  }

  .suffixes{
    color: #95a7b7;
    position: absolute;
    bottom: 11px;
    right: 7px;
    font-size: 14px;
    line-height: 1;
  }
  .has-suffixes{
    padding-right: 2.2rem;
    &.is-invalid, &.is-warning{
      padding-right: 2.2rem !important;
    }
  }

  &.label-form {
    input {
      border: none;
      background: white;
      &:disabled,
      &[readonly] {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
      }
    }
  }

  &.is-warning {
    .form-control {
      border-color: #ffc107;
      border-width: 2px;
      border-style: solid;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
      }
    }

    .react-datepicker-wrapper {
      input {
        border-color: #ffc107;
        border-width: 2px;
      }
    }
  }
  &.is-error {
    .form-control {
      border-color: #dc3545;
      border-width: 2px;
      border-style: solid;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
      }
    }

    .react-datepicker-wrapper {
      input,
      input:disabled {
        border-color: #dc3545;
        border-width: 2px;
      }
    }
  }

  .show-password-icon {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    color: var(--color-primary);
    cursor: pointer;
  }
}
.react-datepicker-popper{
  z-index: 99999 !important;
}
.react-datepicker__triangle {
  position: absolute;
  left: 50px !important;
  transform: none !important;
  z-index: 1;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -7px !important;
}
.react-datepicker__current-month,
.react-datepicker-year-header {
  display: none;
}
.react-datepicker__navigation-icon--previous::before {
  top: 13px !important;
  right: 0px !important;
}
.react-datepicker__navigation-icon--next::before {
  top: 13px !important;
  left: 0px !important;
}
.react-datepicker__header__dropdown {
  margin-bottom: 5px;
}
.react-datepicker__year-wrapper {
  max-width: 210px !important
}
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  border: 1px solid #ddd;
  background: #fff;
  padding: 0 5px;
  border-radius: 3px;
  font-weight: bold;
  position: relative;
  margin: 0 3px;
  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    visibility: visible !important;
    display: flex;
    align-items: center;
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__year-read-view--down-arrow {
      position: static;
      order: 2;
      margin-left: 5px;
      border-color: #000;
      border-style: solid;
      border-width: 1px 1px 0 0;
      width: 6px;
      height: 6px;
      margin-top: -3px;
    }
  }
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  white-space: nowrap;
  width: auto !important;
  left: 0 !important;
  top: calc(100% + 5px) !important;
  min-width: 100%;
  background: #fff !important;
  border: 1px solid #ddd !important;
  .react-datepicker__month-option,
  .react-datepicker__year-option {
    padding: 0 5px;
    &:hover {
      background: #f0f0f0;
    }
    &.react-datepicker__month-option--selected_month,
    &.react-datepicker__year-option--selected_year {
      background: #1c79b7;
      color: #fff;
    }
  }
  .react-datepicker__month-option--selected,
  .react-datepicker__year-option--selected {
    display: none;
  }
}
.react-datepicker__navigation--years {
  height: 20px !important;
  width: 100% !important;
  top: 0;
  text-indent: initial;
  &:after {
    content: "";
    border-color: #000;
    border-style: solid;
    border-width: 1px 1px 0 0;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
  &.react-datepicker__navigation--years-upcoming:after {
    top: 80%;
  }
  &.react-datepicker__navigation--years-previous:after {
    transform: translateX(-50%) translateY(-50%) rotate(135deg);
  }
  &.react-datepicker__navigation--years-previous,
  &.react-datepicker__navigation--years-upcoming {
    top: 0;
  }
}
