._quality-control-add {
  ._card-customize {
    position: relative;
    ._card-header {
      display: flex;
      flex-wrap: wrap;

      .add-buttons {
        display: flex;
        margin-left: auto;
      }

      .checkbox-collection {
        display: flex;
        gap: 3rem;
        margin-right: 2rem;
        margin-left: 2rem;
        height: 1rem;
      }
    }

    ._search-form {
      width: 100%;
      .search-data-box {
        position: absolute;
        &.boder {
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px,
            rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.2) 0px 8px 4px,
            rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.2) 0px 32px 16px;
        }
      }
    }

    ._card-body {
      background: transparent;
      padding: 0;
    }

    .body {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      #main-card {
        flex: 0 79%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-right: 0.75rem;
        height: 80vh;
        min-height: 49rem;

        .card-body {
          padding: 0.75rem;
          overflow-x: hidden;
          overflow-y: auto;

          .search-data-box {
            border: 1px solid #000;
          }

          button.add-product-button {
            padding: 0.5rem 0.5rem;
            svg {
              font-size: 1.5rem;
              margin-right: 0.25rem;
            }
          }
        }
      }

      ._bill-info {
        flex: 0 20%;
        height: 80vh;
        min-height: 49rem;
        overflow: hidden;
        overflow-y: auto;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .card-body {
          overflow-x: hidden;
          overflow-y: auto;
        }
      }

      @media screen and (max-width: 1200px) {
        display: block;
        #main-card {
          margin-right: 0;
          min-height: 10rem;
        }
        #main-card,
        ._bill-info {
          height: unset;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
