._form-customize {
  .radio-group {
    display: flex;
    gap: 1.5rem;
    flex: 0 1 50%;
    label {
      position: relative;
      top: initial;
      opacity: 1;
      left: initial;
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      padding-left: 24px;
      cursor: pointer;
      user-select: none;
      input {
        outline: none;
        position: absolute;
        width: 25px;
        height: 25px;
        opacity: 0;
        left: 0;
        z-index: 9;
        cursor: pointer;
        &:checked {
          ~ .checkmark {
            border-color: var(--color-primary);
            &:after {
              background-color: var(--color-primary);
              -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
            }
          }
        }
        &:focus {
          ~ .checkmark {
            outline: 2px auto var(--color-primary);
          }
        }
      }
      span {
        color: #333333;
      }
      .checkmark {
        border: 1px solid #c4cdd5;
        -webkit-box-shadow: 0 1px 0 0 rgb(22 29 37 / 5%);
        box-shadow: 0 1px 0 0 rgb(22 29 37 / 5%);
        border-radius: 100%;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        &:after {
          content: "";
          display: block;
          height: 8px;
          width: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 100%;
          background-color: transparent;
          -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
          -webkit-transition: -webkit-transform 0.15s ease-in-out;
          transition: -webkit-transform 0.15s ease-in-out;
          transition: transform 0.15s ease-in-out;
          transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        }
      }
      &:after {
        display: none;
      }
    }
  }
}
