._item-component {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0;
  margin: 0.5rem 0;
  border: 1px solid #28a745;
  border-top-width: 3px;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.2) 0px 3px 7px -3px;

  border-style: ridge;
  position: relative;
  padding-right: 3rem;
  margin-bottom: 1rem;

  ._close-button-component {
    align-self: flex-start;
    margin-left: -0.5rem;
    color: #28a745;
    position: absolute;
    right: 0.75rem;
    svg {
      font-size: 1.5rem;
    }
  }
}
