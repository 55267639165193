// $btn-focus: #b8b51a;
.react-confirm-alert-overlay {
    z-index: 999;
    .react-confirm-alert {
        ._customUIDialog {
            .react-confirm-alert-button-group {
                button:focus {
                    // background-color: $btn-focus !important;
                    // border-color: $btn-focus !important;
                    outline: none;
                    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
                }
                #_customUIDialog-focus-guard {
                    position: fixed;
                    top: -100%;
                    left: -100%;
                    height: 0;
                    width: 0;
                }
            }
        }
    }
}
._customUIDialog-d-none {
    display: none !important;
}
