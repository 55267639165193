._loaderOverlay {
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2001;
}
._loaderContainer {
  &.white {
    ._loaderOverlay {
      background: #fff;
    }
  }

  z-index: 2000;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;

  .logo {
    width: 17rem;
    height: 17rem;
    margin-bottom: 50px;
  }
  ._loaderContent {
    z-index: 2002;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.hidden {
    display: none;
  }
}
._loader {
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: flex;
  padding-top: 0;
  margin-left: auto;
  margin-right: auto;
  span {
    display: block;
    width: 20px;
    height: 20px;
    background: #eee;
    border-radius: 50%;
    margin: 0 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    background: var(--color-primary);
    background: #1c79b7;
  }
  span:not(:last-child) {
    animation: loaderAnimate 1.5s linear infinite;
  }
  @keyframes loaderAnimate {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(30px);
    }
  }
  @keyframes loaderAnimateSmall {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(20px);
    }
  }
  span:last-child {
    animation: loaderJump 1.5s ease-in-out infinite;
  }
  @keyframes loaderJump {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(10px, -10px);
    }
    20% {
      transform: translate(20px, 10px);
    }
    30% {
      transform: translate(30px, -50px);
    }
    70% {
      transform: translate(-150px, -50px);
    }
    80% {
      transform: translate(-140px, 10px);
    }
    90% {
      transform: translate(-130px, -10px);
    }
    100% {
      transform: translate(-120px, 0);
    }
  }
  @keyframes loaderJumpSmall {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(10px, -5px);
    }
    20% {
      transform: translate(20px, 5px);
    }
    30% {
      transform: translate(30px, -25px);
    }
    70% {
      transform: translate(-110px, -25px);
    }
    80% {
      transform: translate(-100px, 5px);
    }
    90% {
      transform: translate(-90px, -5px);
    }
    100% {
      transform: translate(-80px, 0);
    }
  }
}
