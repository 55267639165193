._quality-control-bill-info {
  min-height: auto !important;
  height: auto !important;

  .form-inline {
    margin: 2rem 1rem;
    .list-group {
      .list-group-item {
        height: 5rem;
      }
    }
  }
}
