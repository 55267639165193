._card-customize {
  ._card-header {
    margin-bottom: 1rem;
    border-radius: 10px;
    background-color: white;
    padding: 0.75rem 1.25rem;
  }
  ._card-body {
    border-radius: 10px;
    background-color: white;
    padding: 0.75rem 1.25rem;
    min-height: 70vh;
    overflow-x: auto;
  }

}

@media (min-width: 361px) and (max-width: 480px)
{
  ._card-customize
  {
    ._card-body
    {
      overflow-x: initial;
      ._group-export-buttons
      {
        margin-top: 1rem;
      }
      ._box-table
      {
        overflow-x: auto;
      }
    }
  }
}