._bill-info {
  min-width: 16rem;

  .card {
    min-height: 80vh;
  }

  .form-inline {
    display: block;

    .list-group {
      .list-group-item {
        border: none;
        padding: 0.5rem 0;
      }
      .form-group {
        display: flex;
        justify-content: space-between;

        .dropdown-filter,
        select,
        input,
        textarea {
          flex-grow: 1;
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
          width: 30%;
        }
        .dropdown-filter-input {
          width: 100%;
        }
        #point {
          flex: 1;
          margin-right: 5px;
        }
        #moneyDiscountPoint {
          flex: 1;
          margin-left: 5px;
        }
        textarea {
          background-color: rgba($color: #ccc, $alpha: 0.3);
          border-radius: 5px;
        }

        label {
          flex-grow: 0;

          &::after {
            content: ':';
            margin-right: 5px;
          }
        }

        .btn {
          margin-left: 7px;
        }
      }
    }
  }

  ._line {
    display: flex;
    justify-content: space-between;
    // margin-top: 0.2rem;
    &.hidden {
      display: none;
    }
    & p {
      margin-bottom: 0.5rem;
    }

    .label::after {
      content: ':';
    }

    &#requiredPayCost {
      .value {
        font-weight: bold;
        color: var(--color-primary);
      }
    }
  }

  .card-footer,
  .card-header {
    text-align: center;
  }

  .card-footer {
    padding: 0;
    background-color: transparent;
    border: none;
    transform: translateY(-1rem);
  }

  .card-body {
    padding: 0;
  }

  .row.submit-button {
    justify-content: center;

    .btn {
      margin-top: 0.5rem;
      padding: 0.5rem 0.75rem;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}
